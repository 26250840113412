const SiteConstant = {
    "localhost": {
        title: "LocalHost",
        logo: "/images/logos/14.png"
    }, 
    "fedbenefitpro.com": {
        title: "Fed Benefit Pro",
        logo: "/images/logos/1.png"
    }, 
    "fedbenefitpros.com": {
        title: "Fed Benefit Pros",
        logo: "/images/logos/2.png"
    }, 
    "fedbenspecialists.com": {
        title: "Fed Ben Specialists",
        logo: "/images/logos/5.png"
    }, 
    "fedbenefitspec.com": {
        title: "Fed Benefit Spec",
        logo: "/images/logos/6.png"
    },
    "fedbenefitsspec.com": {
        title: "Fed Benefits Spec",
        logo: "/images/logos/7.png"
    }, 
    "fedbenservice.com": {
        title: "Fed Ben Service",
        logo: "/images/logos/8.png"
    }, 
    "fedbenefitspecialists.com": {
        title: "Fed Benefit Specialists",
        logo: "/images/logos/9.png"
    },
    "fedbenserv.com": {
        title: "Fed Ben Serv",
        logo: "/images/logos/10.png"
    },
    "fedbenspecialist.com": {
        title: "Fed Ben Specialist",
        logo: "/images/logos/11.png"
    }, 
    "fedbenefitsspecialist.com": {
        title: "Fed Benefits Specialist",
        logo: "/images/logos/12.png"
    },
    "fedbenefitspecialist.com": {
        title: "Fed Benefit Specialist",
        logo: "/images/logos/13.png"
    },
    "fedbenefitsspecialists.com": {
        title: "Fed Benefits Specialists",
        logo: "/images/logos/14.png"
    }, 
    "fedbenpro.com": {
        title: "Fed Ben Pro",
        logo: "/images/logos/15.png"
    },  
    "fedempben.com": {
        title: "Fed Emp Ben",
        logo: "/images/logos/16.png"
    }, 
    "fedempservice.com": {
        title: "Fed Emp Service",
        logo: "/images/logos/17.png"
    },  
    "fedempservices.com": {
        title: "Fed Emp Services",
        logo: "/images/logos/18.png"
    },   
}

export default SiteConstant;